<template>
  <div class="study">
    <div class="stuHeader">
      <div>
        课后练习
      </div>
      <div>
        {{curQues+1}}/{{list.length}}
      </div>
    </div>
    <a-spin class="stuContent" :spinning="loading" v-if="list.length>0">
      <div class="wrap">
        <div class="g-flex">
          <span>{{curQues+1}}. </span>
          <div v-html="list[curQues].content"></div>
        </div>
        <div v-if="list[curQues].answer.length<=1">
          <a-radio-group v-model="result">
            <a-radio v-for="(item, index) in list[curQues].option_list" :key="index" :value="optionType[index]" class="option_item">
              <div class="g-flex">
                <span style="margin-right:5px">{{optionType[index]}}.</span>
                <div v-html="item" style="white-space:break-spaces"></div>
              </div>
            </a-radio>
          </a-radio-group>
        </div>
        <div v-else>
          <a-checkbox-group v-model="result">
            <a-checkbox v-for="(item, index) in list[curQues].option_list" :key="index" :value="optionType[index]" class="option_item">
              <div class="g-flex">
                <span style="margin-right:5px">{{optionType[index]}}.</span>
                <div v-html="item" style="white-space:break-spaces"></div>
              </div>
            </a-checkbox>
          </a-checkbox-group>
        </div>
        <div v-if="Array.isArray(result)">
          <div v-if="result.length>=list[curQues].answer.length" class="gc-red">正确答案：{{list[curQues].answer}}</div>
        </div>
        <div v-else>
          <div class="gc-red" v-if="result">正确答案：{{list[curQues].answer}}</div>
        </div>

      </div>
    </a-spin>

    <div class="stubottom">
      <a-button type="primary" icon="backward" style="background: #006daa;" @click="back">
        关闭
      </a-button>
      <div>
        <a-button type="primary" icon="backward" style="background: #006daa;" @click="prev" v-if="curQues>0">
          上一题
        </a-button>
        <a-button type="primary" icon="forward" class="gml-10" style="background: #006daa;" @click="next" v-if="curQues<list.length-1">
          下一题
        </a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getImportantDetail } from "@/api/articlePaper";

export default {
  data() {
    return {
      list: [],
      loading: false,
      optionType: ["A", "B", "C", "D", "E", "F", "G", "H"],
      result: "",
      curQues: 0,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getImportantDetail();
  },
  methods: {
    //机经详情
    getImportantDetail() {
      this.loading = true;
      getImportantDetail({ id: this.id }).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          this.list = res.data.practice_list;
        }
      });
    },
    back() {
      this.$router.back();
    },
    next() {
      if (this.curQues >= this.list.length - 1) {
        this.back();
      } else {
        this.curQues = this.curQues + 1;
        this.result = this.list[this.curQues].answer.length > 1 ? [] : "";
      }
    },
    prev() {
      if (this.curQues > 0) {
        this.curQues = this.curQues - 1;
        this.result = this.list[this.curQues].answer.length > 1 ? [] : "";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.study {
  .wrap {
    width: 800px;
    margin: auto;
  }
  .box {
    width: 400px;
    margin: auto;
  }
  height: 100vh;
  /deep/ img {
    max-height: 300px;
  }
  .stuHeader,
  .stubottom {
    width: 100%;
    background: #006daa;
    color: #ffffff;
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  .stuHeader {
    height: 50px;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .stubottom {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
  }
  .stuContent {
    overflow-y: auto;
    padding: 80px 0 72px 0;
  }
  .option_item {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    color: #000;
    margin-left: 0;
  }
}
</style>